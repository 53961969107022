import _ from 'lodash';
import RatingComponentHTML from './rating-stars';

const View = {};

View.displayElement = function (index) {
  const { fetchedData } = this;
  const widgetID = fetchedData.id || 'preview';

  const widgetDataFunc = window.rs_data[`widget_${widgetID}`];
  if (!widgetDataFunc) {
    console.error('RS ERROR! Widget data not found!');
    return false;
  }

  let elements = widgetDataFunc('displayElements', []);
  if (elements.length === 0) {
    elements = [0, 1, 2, 3, 4];
  }
  const result = _.indexOf(elements, index);
  return result > -1;
};

View.reviewHTML = function ({ userName, userPic, text, date, sourcePic, avgRating, twitterUrl, reviewUrl }) {
  const { namespace, fetchedData } = this;
  const ratingHTML = RatingComponentHTML.call(this, avgRating);
  const userPicHTML = this.userPicHTML({ userPic, userName });
  const sourcePicHTML = this.sourcePicHTML({ sourcePic });
  const sourcePicLinkHTML = this.sourcePicLinkHTML({ reviewUrl, sourcePicHTML });
  const { sourceText } = fetchedData;
  const widgetID = fetchedData.id || 'preview';

  // const reviewFooterLinksHTML = `
  //  <div class="${namespace}__reviewFooter__links">
  //    <a href="${twitterUrl.replace(/"/g, "")}" target="_blank" class="${namespace}__footerTwitter"></a>
  //  </div>
  // `;

  return `
    <div class="${namespace}__review">
      <div class="${namespace}__reviewTop">
        <div class="${namespace}__reviewUserProfile">
          ${userPicHTML}

          <div class="${namespace}__reviewUserProfile__right">
            <div style="${this.displayElement(3) ? 'margin-bottom: 20px' : 'display: none'}">${ratingHTML}</div>
            <div class="${namespace}__reviewUserProfile__name" ${this.displayElement(0) ? '' : 'style="display:none"'}>${userName}</div>
          </div>
        </div>
        <div class="${namespace}__reviewText" ${this.displayElement(4) ? '' : 'style="display:none"'}>${this.truncatedTextHTML(text)}</div>
        <div class="${namespace}__reviewFooter" ${this.displayElement(2) ? '' : 'style="display:none"'}>
          <div class="${namespace}__reviewFooter__time">${date}</div>
        </div>
      </div>

      <div class="${namespace}__reviewBottom">
        <div>${sourceText}:</div>
        ${sourcePicLinkHTML}
      </div>
    </div>
  `;
};

View.listSimpleReviewHTML = function ({ userPic, userName, text, sourcePic, date, avgRating, reviewUrl }) {
  const { namespace } = this;
  const userPicHTML = this.userPicHTML({ userPic, userName });
  const ratingHTML = RatingComponentHTML.call(this, avgRating);
  const sourcePicHTML = this.sourcePicHTML({ sourcePic });
  const sourcePicLinkHTML = this.sourcePicLinkHTML({ reviewUrl, sourcePicHTML });

  return `
    <div class="${namespace}__simpleReview">
      <div class="${namespace}__simpleReviewLeft">
        ${userPicHTML}
        ${sourcePicLinkHTML}
      </div>
      <div class="${namespace}__simpleReviewRight">
        <div class="${namespace}__simpleReviewTop">
          <div class="${namespace}__simpleReviewName">${userName}</div>
          <div class="${namespace}__simpleReviewText">${text}</div>
        </div>

        <div class="${namespace}__simpleReviewFooter">
          <div>${date}</div>
          ${ratingHTML}
        </div>
      </div>
    </div>
  `;
};

View.sourcePicLinkHTML = function ({ reviewUrl, sourcePicHTML }) {
  if (!reviewUrl) return sourcePicHTML;

  const { namespace } = this;
  return `
    <a href="${reviewUrl}" target="_blank" class="${namespace}__sourcePicLink">
      ${sourcePicHTML}
    </a>
  `;
};

View.sourcePicHTML = function ({ sourcePic }) {
  const { namespace } = this;

  return `<img src="${sourcePic}" class="${namespace}__sourcePic">`;
};

View.userPicHTML = function ({ userPic, userName }) {
  const { namespace } = this;
  const userPicHTML = attr => `<div class="${namespace}__userProfilePic" ${this.displayElement(1) ? attr : 'style="display:none"'}></div>`;

  return userPic ? userPicHTML(`style="background-image: url(${userPic})"`) : userPicHTML(`data-word="${userName[0]}"`);
};

View.reviewsColumnHTML = function ({ reviewsHTML }) {
  const { namespace } = this;

  return `
    <div class="${namespace}__reviewsColumn">${reviewsHTML}</div>
  `;
};

View.reviewsColumnContainerHTML = function ({ columnsHTML, size }) {
  const { namespace } = this;

  return `
    <div class="${namespace}__reviewsContainer">
      ${columnsHTML}
    </div>
  `;
};

View.averageHTML = function ({ title, ratingHTML, reviewsNum }) {
  const { namespace, fetchedData } = this;
  const { reviewsText } = fetchedData;

  return `
    <div class="${namespace}__average">
      <div class="${namespace}__averageTitle">${title}</div>
      <div class="${namespace}__averageRating">${ratingHTML}</div>
      <div class="${namespace}__averageReviews">
        ${reviewsNum} ${reviewsText}
      </div>
    </div>
  `;
};

View.logoImageHTML = function (src) {
  return `
    <img src="${src}" style="max-width: 6.25em; padding: 0.15em" />
  `;
};

View.logosHTML = function ({ logoPics }) {
  const { namespace } = this;
  const logoImagesHTML = logoPics.map(this.logoImageHTML).join('');

  return `
    <div class="${namespace}__logos">${logoImagesHTML}</div>
  `;
};

View.topHTML = function ({ averageAndLogosHTML }) {
  const { namespace } = this;

  return `
    <div class="${namespace}__top">${averageAndLogosHTML}</div>
  `;
};

View.ratingWithNumHTML = function ({ avgRating }) {
  const { namespace } = this;
  const ratingHTML = RatingComponentHTML.call(this, avgRating);

  return `
    <div class="${namespace}__ratingBody">
      ${ratingHTML}

      <div class="${namespace}__ratingBodyNum">
        ${Number(avgRating).toFixed(1)}
      </div>
    </div>
  `;
};

View.quoteHTML = function ({ userName, text, date }) {
  const { namespace } = this;

  return `
    <div class="${namespace}__quoteContainer">

        <div class="${namespace}__quoteLeft"></div>
        <div class="${namespace}__quoteRight">
          <div class="${namespace}__quoteName">${userName}</div>
          <div class="${namespace}__quoteText">${text}</div>
          <div class="${namespace}__quoteDate">${date}</div>
        </div>

    </div>
  `;
};

View.sliderHTML = function () {
  const { namespace, fetchedData } = this;
  const { reviews, summaryHeader } = fetchedData;
  const reviewsHTML = reviews.map(this.reviewHTML, this).join('');
  const sliderConfig = this.specialConfig;

  return `
    <div class="${namespace}__sliderComponent" data-slides="${sliderConfig.num}" data-special-bp="${sliderConfig.specialBP}" data-summary-header="${sliderConfig.summaryHeader}">
      <div class="${namespace}__sliderControls">
        <a href="javascript:void(0)" class="${namespace}__sliderArrow ${namespace}__sliderArrow-left"></a>
        <a href="javascript:void(0)" class="${namespace}__sliderArrow ${namespace}__sliderArrow-right"></a>
      </div>

      <div class="${namespace}__sliderBody">
        <div class="${namespace}__slider">
          ${reviewsHTML}
        </div>
      </div>
    </div>
  `;
};

// Components

View.gridComponentHTML = function () {
  const { responsiveBreakpointNum } = this;
  const reviewsByColumns = this.sortReviewsByColumns();

  const columnsHTML = reviewsByColumns.map((column) => {
    const reviewsHTML = column.map(this.reviewHTML, this).join('');
    return this.reviewsColumnHTML({ reviewsHTML });
  }).join('');

  return this.reviewsColumnContainerHTML({ columnsHTML, size: responsiveBreakpointNum });
};

View.topComponentHTML = function () {
  const { fetchedData } = this;
  const {
    avgRating,
    logoPics,
    title,
    reviewsNum,
  } = fetchedData;

  const logosHTML = this.logosHTML({ logoPics });
  const ratingHTML = this.ratingWithNumHTML({ avgRating });
  const averageHTML = this.averageHTML({ title, ratingHTML, reviewsNum });
  const averageAndLogosHTML = [averageHTML, logosHTML].join('');

  return this.topHTML({ averageAndLogosHTML });
};

View.quoteComponentHTML = function () {
  const { namespace, fetchedData } = this;
  const { reviews } = fetchedData;
  const quotesHTML = reviews.map(this.quoteHTML, this).join('');

  return `
    <div class="${namespace}__quotesContainer">
      ${quotesHTML}
    </div>
  `;
};

View.listComponentHTML = function () {
  const { namespace, fetchedData } = this;
  const type = this.settings.options.list_type;
  const { reviews } = fetchedData;
  const reviewHTMLfunc = type === 'advanced' ? this.reviewHTML : this.listSimpleReviewHTML;
  const reviewsHTML = reviews.map(reviewHTMLfunc, this).join('');

  return `
    <div class="${namespace}__list${_.upperFirst(type)}Container">
      ${reviewsHTML}
    </div>
  `;
};

View.sliderComponentHTML = function () {
  const { namespace, fetchedData } = this;
  const { reviews } = fetchedData;
  const sliderHTML = this.sliderHTML(reviews);

  return `
    <div class="${namespace}__sliderContainer">
      ${sliderHTML}
    </div>
  `;
};

View.carouselComponentHTML = function () {
  const { namespace, fetchedData } = this;
  const { reviews } = fetchedData;
  const sliderHTML = this.sliderHTML(reviews);

  return `
    <div class="${namespace}__carouselContainer">
      ${sliderHTML}
    </div>
  `;
};

View.truncatedTextHTML = function (sourceTextHTML) {
  const maxTextLength = 220;
  const testEl = document.createElement('div');
  testEl.innerHTML = sourceTextHTML;
  const sourceText = testEl.textContent;

  if (sourceText.length <= maxTextLength) return sourceTextHTML;

  const { namespace } = this;
  const textTruncated = sourceText.slice(0, maxTextLength);

  return `
    <div class="${namespace}__textTruncated">
      <span class="${namespace}__textTruncatedBody">${textTruncated}</span>
      <span class="${namespace}__textTruncatedSourceBody ${namespace}" >${sourceTextHTML}</span>
      <a href="javascript:;" class="${namespace}__textTruncatedLink"></a>
    </div>
  `;
};

View.richSnippetScript = function () {
  const { fetchedData } = this;
  const { avgRating, reviewsNum } = fetchedData;

  const query = {
    '@context':      'http://schema.org',
    '@type':         'LocalBusiness',
    '@id':           window.location.hostname, // <current base url>/<location name>
    name:            window.location.hostname, // "<client/account name> <location name>"
    aggregateRating: {
      '@type':     'AggregateRating',
      ratingValue: avgRating,
      ratingCount: reviewsNum,
    },
  };

  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.innerHTML = JSON.stringify(query);

  return script;
};

export default View;

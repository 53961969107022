export const STORAGE_LOCAL = "local_storage";
export const STORAGE_COOKIE = "cookie_storage";

class LocalStorageWrapper {
    /**
     * Retrieves the value stored with specified key from Storage. If an object was stored, it will be automatically deserialized
     * @param key {string} The key to return value against
     * @return {any}
     */
    get(key){
        let data = undefined;
        if (this._checkStorage()) {
            data = localStorage.getItem(key);
        }

        if(data == undefined && this._checkCookie()) {
            data = this._readCookie(key);
        }

        try {
            data = JSON.parse(data);
        } catch(e) {
            data = data;
        }

        return data;
    }

    /**
     * Stores the value with specified key into storage. If an object is passed, it will be automatically serialized
     * @param key {string} Key to store the value against
     * @param value {any} Value to store
     * @param storage {string} Storage to be used. See storage constant above. If not specified, it will be chose automatically
     */
    set(key, value, storage = undefined){
        if ( typeof value === 'object' ) {
            value = JSON.stringify(value);
        }

        if(storage == undefined) {
            if (this._checkStorage()) {
                localStorage.setItem(key, value);
            } else {
                this._createCookie(key, value, 30);
            }
        } else if (storage == STORAGE_LOCAL && this._checkStorage()) {
            localStorage.setItem(key, value);
        } else if (storage == STORAGE_COOKIE && this._checkCookie()) {
            this._createCookie(key, value, 30);
        }
    }

    /**
     * Remove the value stored against the key from storage
     * @param key {string}
     */
    remove(key){
        if (this._checkStorage()) {
            localStorage.removeItem(key);
        }

        if(this._checkCookie()) {
            this._createCookie(key, '', -1);
        }
    }

    /**
     * Check if one of the two storages (LocalStorage, Cookie) is enabled
     * @return {boolean}
     */
    isAvailable(){
        return this._checkStorage() || this._checkCookie();
    }

    // Private Methods
    _checkStorage(){
        if(localStorage){
            return true;
        }

        return false;
    }

    /**
     * Checks if a cookie is enabled
     * @return {boolean}
     * @private
     */
    _checkCookie(){
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){
            document.cookie = "test";
            cookieEnabled = document.cookie.indexOf("test")!=-1;
        }
        return cookieEnabled;
    }

    /**
     * Store a value in cookie
     * @param key {string} Cookie name
     * @param value {string} Value to store
     * @param exp {number} Number of days to store cookie for
     * @private
     */
    _createCookie(key, value, exp) {
        let date = new Date();
        date.setTime(date.getTime() + (exp * 24 * 60 * 60 * 1000));
        let expires = "; expires=" + date.toGMTString();
        document.cookie = key + "=" + value + expires + "; path=/";
    }

    /**
     * Retrieves a cookie from cookie
     * @param key {string} Key to retrieve cookie against
     * @return {*}
     * @private
     */
    _readCookie(key) {
        let nameEQ = key + "=";
        let ca = document.cookie.split(';');
        for (let i = 0, max = ca.length; i < max; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}

const browserStorage = new LocalStorageWrapper();
export default browserStorage;
import WidgetView from './widget_view';
import WidgetRender from './widget_render';
import WidgetSlider, { GetSliderConfig } from './widget_slider';

const request = require('request-promise');

const Widget = {
  fetchedData:             null,
  parentContainerElement:  null,
  responsiveBreakpointNum: null,
  namespace:               'reviewshakeWidget',
  type:                    null,
  settings:                null,
  specialConfig:           null,
  render:                  WidgetRender,
  sliderInit:              WidgetSlider,
  getSliderConfig:         GetSliderConfig,
};

Widget.view = Object.assign(Object.create(Widget), WidgetView);

Widget.getResponsiveBreakpointNum = function () {
  const tabletWidth = 1100;
  const mobileWidth = 728;
  const parentContainerWidth = this.parentContainerElement.offsetWidth;
  let responsiveBreakpointNum = null;

  if (parentContainerWidth < mobileWidth) {
    responsiveBreakpointNum = 1;
  } else if (parentContainerWidth < tabletWidth) {
    responsiveBreakpointNum = 2;
  } else {
    responsiveBreakpointNum = 3;
  }

  return responsiveBreakpointNum;
};

Widget.getSpecialConfig = function () {
  let config = null;

  if (this.type === 'slider' || 'carousel') {
    config = this.getSliderConfig();
  }

  return config;
};

Widget.sortReviewsByColumns = function () {
  const { responsiveBreakpointNum } = this;
  const { reviews } = this.fetchedData;
  const reviewsSorted = [];

  reviews.reduce((currentColNum, review) => {
    reviewsSorted[currentColNum] || (reviewsSorted[currentColNum] = []);
    reviewsSorted[currentColNum].push(review);

    return currentColNum < (responsiveBreakpointNum - 1) ? ++currentColNum : 0;
  }, 0);

  return reviewsSorted;
};

Widget.getAttributeData = function (attribute, isJSON) {
  const data = this.parentContainerElement.getAttribute(attribute);
  return isJSON ? JSON.parse(data) : data;
};

Widget.init = function (parentContainerElement) {
  this.parentContainerElement = parentContainerElement;

  const settings = this.settings = {
    requestParams: this.getAttributeData('data-params', true),
    host:          this.getAttributeData('data-host'),
    options:       this.getAttributeData('data-options', true),
  };

  this.type = settings.requestParams.type;

  const requestOptions = {
    method: 'GET',
    uri:    `${settings.host}/api/v1/public/widgets.json`,
    qs:     settings.requestParams,
  };

  request(requestOptions).then((response) => {
    this.fetchedData = JSON.parse(response);

    this.render();
    window.addEventListener('resize', this.render.bind(this));
  });
};

export default Widget;

export default function (scopeEl) {
  const $$ = selector => scopeEl.querySelectorAll(selector);
  const { namespace, fetchedData } = this;
  const { displayMode } = fetchedData;

  const renderTarget = window.rs_render_target;

  // Widget mode 0 is only having summary section - ignore slider handling (except when inside editor)
  const shouldShowReview = renderTarget === 'preview' || (_.isNumber(displayMode) && displayMode > 0);

  if (shouldShowReview === false) {
    return;
  }

  const selector = {
    component:  `.${namespace}__sliderComponent`,
    arrowLeft:  `.${namespace}__sliderArrow-left`,
    arrowRight: `.${namespace}__sliderArrow-right`,
    slider:     `.${namespace}__slider`,
    slide:      `.${namespace}__review`,
  };
  const slidesNum = $$(selector.component)[0].querySelectorAll(selector.slide).length;

  let currentSlideNum = 0;
  const visibleSlidesNum = Number($$(selector.component)[0].getAttribute('data-slides'));

  $$(selector.arrowLeft)[0].addEventListener('click', handleArrowClick.bind(this, 'left'));
  $$(selector.arrowRight)[0].addEventListener('click', handleArrowClick.bind(this, 'right'));

  function handleArrowClick(direction) {
    let nextSlideIndex = null;

    if (direction === 'right') {
      nextSlideIndex = ifSlideExist(currentSlideNum + visibleSlidesNum) ? (currentSlideNum + 1) : 0;
    }

    if (direction === 'left') {
      nextSlideIndex = ifSlideExist(currentSlideNum - 1) ? (currentSlideNum - 1) : (slidesNum - visibleSlidesNum);
    }

    moveSlider(nextSlideIndex);
  }

  function moveSlider(slideIndex) {
    const slideOffset = parseInt(window.getComputedStyle($$(selector.slide)[0]).getPropertyValue('margin-right'));
    const slideWidth = parseInt($$(selector.slide)[0].offsetWidth);
    const offset = (slideWidth + slideOffset) * slideIndex;

    setTransform(-offset);
    currentSlideNum = slideIndex;
  }

  function ifSlideExist(slideNum) {
    const nextSlideIndex = $$(selector.component)[0].querySelectorAll(selector.slide)[slideNum];
    return !!nextSlideIndex;
  }

  function setTransform(offset) {
    const prefixes = ['-webkit-', '-moz-', '-ms-', '-o-', ''];
    const style = prefixes.map(prefix => `${prefix}transform: translateX(${offset}px)`).join('; ');
    $$(selector.slider)[0].setAttribute('style', style);
  }
}

export function GetSliderConfig() {
  const parentContainerWidth = this.parentContainerElement.offsetWidth;
  const { fetchedData } = this;
  const { summaryHeader } = fetchedData;

  const config = {
    num:           null,
    specialBP:     null,
    summaryHeader: summaryHeader ? 'true' : 'false',
  };

  // Design needs

  if (this.type === 'slider') {
    config.num = parentContainerWidth > 750 ? 2 : 1;
    config.specialBP = parentContainerWidth < 440 ? 'smallNoUserPic' : null;
  }

  if (this.type === 'carousel') {
    if (!summaryHeader) {
      config.num = parentContainerWidth > 1060 ? 3 : (parentContainerWidth > 726 ? 2 : 1);
      config.specialBP = parentContainerWidth < 440 ? 'smallNoUserPic' : null;
    }

    if (summaryHeader) {
      config.num = parentContainerWidth >= 1160 ? 3 : (parentContainerWidth > 900 ? 2 : 1);
      config.specialBP = parentContainerWidth < 440 ? 'smallNoUserPic' : null;
    }
  }

  return config;
}

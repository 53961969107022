export default function () {
  const responsiveBreakpointNum = this.getResponsiveBreakpointNum();
  const specialConfig = this.getSpecialConfig();
  const isBreakpointChanged = responsiveBreakpointNum !== this.responsiveBreakpointNum;
  const isSpecialConfigChanged = JSON.stringify(specialConfig) !== JSON.stringify(this.specialConfig);

  if (!isBreakpointChanged && !isSpecialConfigChanged) return;

  this.responsiveBreakpointNum = responsiveBreakpointNum;
  this.specialConfig = specialConfig;

  const { namespace, type, fetchedData } = this;
  const { id, summaryHeader, displayMode } = fetchedData;

  const renderTarget = window.rs_render_target;
  const widgetID = id || 'preview';

  // Widget mode 0 is only having summary section - ignore slider handling (except when inside editor)
  const shouldShowReview = renderTarget === 'preview' || (_.isNumber(displayMode) && displayMode > 0);

  const componentHTML = this.view[`${type}ComponentHTML`]();
  const isSlider = (['slider', 'carousel'].indexOf(type) !== -1);

  const reviewContentHTML = `
    <div class="${namespace}__content">
      ${componentHTML}
    </div>
  `;

  this.parentContainerElement.innerHTML = `
    <div 
      class="${namespace}" 
      data-breakpoint="${responsiveBreakpointNum}"
      data-widget-type="${type}"
      data-widget-id="${widgetID}"
      ${summaryHeader ? 'data-summary-header' : ''}>
      ${summaryHeader ? this.view.topComponentHTML() : ''}
      ${shouldShowReview ? reviewContentHTML : ''}
    </div>
  `;

  const event = new Event('onRender');
  window.dispatchEvent(event);

  // DOM handlers init
  isSlider && this.sliderInit(this.parentContainerElement);
  truncateTextInit.apply(this);
}

function truncateTextInit() {
  const { namespace } = this;
  const linkNodeList = this.parentContainerElement.querySelectorAll(`.${namespace}__textTruncatedLink`);

  const handleClick = (event) => {
    event.target.closest(`.${namespace}__textTruncated`).classList.toggle(`${namespace}__textTruncated-source`);
    event.target.classList.toggle(`${namespace}__textTruncatedLink-less`);
  };

  Array.from(linkNodeList).forEach((elem) => {
    elem.addEventListener('click', handleClick);
  });
}

import GetNamespace from './_get-namespace';

export default function RatingComponentHTML(ratingNum) {
  const starListHTML = GetStarsArray(ratingNum).map(StarHTML, this).join('');
  return RatingHTML.call(this, starListHTML, ratingNum);
};

export function StarHTML({ratingPercent}) {
  const namespace = GetNamespace(this);

  return `
    <div class="${namespace}__starIcon">
      <div class="${namespace}__starIcon-percent"
        style="width: ${ratingPercent}%"
      ></div>
    </div>
  `;
};

export function RatingHTML(starsHTML, ratingNum) {
  const namespace = GetNamespace(this);
  const attr = ratingNum ? `data-rating-value="${ratingNum}"` : '';

  return `
    <div class="${namespace}__rating" ${attr}>
      ${starsHTML}
    </div>
  `;
};

export function GetStarsArray(ratingNum) {
  let starsArray = [];
  let isDone = null;

  for (let step = 1; step <= 5; step++) {
    let star = {};

    if (!isDone) {
      if (step <= ratingNum) {
        star.ratingPercent = 100;
      }

      if (step > ratingNum) {
        star.ratingPercent = Number('0.' + ratingNum.toString().split('.')[1]) * 100;
      }

      if (step >= ratingNum) {
        isDone = true;
      }
    } else {
      star.ratingPercent = 0;
    }

    starsArray.push(star);
  }

  return starsArray;
};
import GridWidget from "../components/widget";
import browserStorage from "../lib/browserStorage";

const scriptTag = document.scripts[document.scripts.length - 1];
const { parentNode } = scriptTag;

const containerEl = parentNode.querySelector(".rs-widget-container");
containerEl && GridWidget.init(containerEl);

const renderTarget = window.rs_render_target;

// Only intercept iframe event message when inside Widget editor preview
if (renderTarget === "preview") {
  let formatParameters = null;

  const useIframe = containerEl.hasAttribute("data-use-iframe");

  const namespace = "reviewshakeWidget";
  const containerWindow = useIframe ? window.parent : window;

  containerWindow.addEventListener("message", receiveMessage, false);
  containerWindow.addEventListener("onRender", receiveMessage, false);

  function receiveMessage(event) {
    const { data } = event;
    if (data === undefined || typeof data === "string") {
      const parameters = browserStorage.get("editor_params");
      if (parameters !== undefined) {
        formatParameters = parameters;
        formatToParameters(formatParameters, namespace);
      }
    } else if (data.hasOwnProperty("step")) {
      formatParameters = data;
      browserStorage.set("editor_params", formatParameters);
      formatToParameters(formatParameters, namespace);
    }
  }
}

// This function will only be called under Widget editor preview
function formatToParameters(parameters, namespace) {
  if (!parameters) {
    return;
  }

  const {
    id,
    content,
    elementsToDisplay,
    backgroundColor,
    reviewBackgroundColor,
    textColor,
    linkColor,
    buttonColor,
    starColor,
    titleFontSize,
    titleBoldFont,
    bodyFontSize,
    customCss
  } = parameters;

  const widgetMainCSSSelector = "style#rs-widget-preview-css";
  const $widgetMainCSS = $(widgetMainCSSSelector);
  if ($widgetMainCSS) {
    let mainStyle = "";

    mainStyle += ` .rs-widget-container { background-color: ${backgroundColor}; color: ${textColor}; } `;
    mainStyle += ` .rs-widget-container a { color: ${linkColor}; } `;
    mainStyle += ` .rs-widget-container .${namespace}__review { background-color: ${reviewBackgroundColor}; } `;
    mainStyle += ` .rs-widget-container .${namespace}__reviewText, .${namespace}__quoteText { font-size: ${bodyFontSize}px; } `;

    const fontWeight = titleBoldFont === 0 ? "normal" : "bold";
    mainStyle += ` .rs-widget-container .${namespace}__averageTitle { font-size: ${titleFontSize}px; font-weight: ${fontWeight}; } `;

    $widgetMainCSS.html(mainStyle + customCss);
  }

  if (content === 0) {
    $(`.${namespace}__top`).show();
    $(`.${namespace}__content`).hide();
  } else if (content === 1) {
    $(`.${namespace}__top`).hide();
    $(`.${namespace}__content`).show();
  } else {
    $(`.${namespace}__top`).show();
    $(`.${namespace}__content`).show();
  }

  if (_.includes(elementsToDisplay, 0)) {
    $(`.${namespace}__reviewUserProfile__name`).show();
  } else {
    $(`.${namespace}__reviewUserProfile__name`).hide();
  }

  if (_.includes(elementsToDisplay, 1)) {
    $(`.${namespace}__userProfilePic`).show();
  } else {
    $(`.${namespace}__userProfilePic`).hide();
  }

  if (_.includes(elementsToDisplay, 2)) {
    $(`.${namespace}__reviewFooter`).show();
  } else {
    $(`.${namespace}__reviewFooter`).hide();
  }

  if (_.includes(elementsToDisplay, 3)) {
    $(`.${namespace}__rating`).show();
  } else {
    $(`.${namespace}__rating`).hide();
  }

  if (_.includes(elementsToDisplay, 4)) {
    $(`.${namespace}__reviewText`).show();
  } else {
    $(`.${namespace}__reviewText`).hide();
  }
}
